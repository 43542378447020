<template>
  <div style="max-width: 90px; margin-left: 20px">
    <v-text-field
      v-model="season.name"
      :disabled="$store.state.app.onlyShow"
      :label="$t('lbl.name')"
      class="my-input mb-5"
      outlined
      dense
      hide-details
      :append-icon="!$store.state.app.onlyShow ? 'mdi-trash-can' : ''"
      @click:append="deleteSeasonContrateHotel(pos)"
    ></v-text-field>
    <v-menu
      v-model="menuFrom"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedFromDateFormatted"
          :label="$t('lbl.from')"
          :disabled="$store.state.app.onlyShow"
          persistent-hint
          readonly
          v-bind="attrs"
          outlined
          dense
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="season.from"
        no-title
        :min="minDate"
        locale="es"
        :show-current="false"
        @input="
          menuFrom = false
          activeToDate()
        "
      ></v-date-picker>
    </v-menu>
    <!--<vc-date-picker
      v-model="season.from"
      class="mb-0"
      outlined
      dense
      :min-date="new Date()"
      mode="date"
      :model-config="modelConfig"
      @input="activeToDate"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <v-text-field
          :label="$t('lbl.from')"
          :disabled="$store.state.app.onlyShow"
          outlined
          dense
          :value="inputValue"
          class="my-input mb-0"
          v-on="inputEvents"
        >
        </v-text-field>
      </template>
    </vc-date-picker>-->
    <v-menu
      v-model="menuTo"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="computedToDateFormatted"
          :label="$t('lbl.to')"
          :disabled="season.from === null || $store.state.app.onlyShow"
          persistent-hint
          readonly
          v-bind="attrs"
          outlined
          dense
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="season.to"
        no-title
        locale="es"
        :show-current="
          season.from
            ? $moment(season.from)
              .toDate()
              .toISOString()
              .substr(0, 8)
            : false
        "
        :min="
          season.from
            ? $moment(season.from)
              .add(1, 'day')
              .toDate()
              .toISOString()
              .substr(0, 10)
            : ''
        "
        @input="menuTo = false"
        @change="sugerirNextSeason"
      ></v-date-picker>
    </v-menu>
    <!--<vc-date-picker
      v-model="season.to"
      class="mt-0"
      outlined
      dense
      :min-date="
        $moment(season.from)
          .add(1, 'day')
          .toDate()
      "
      mode="date"
      :model-config="modelConfig"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <v-text-field
          ref="dateTo"
          :label="$t('lbl.to')"
          :disabled="season.from === null || $store.state.app.onlyShow"
          outlined
          dense
          :value="inputValue"
          class="my-input mt-0"
          v-on="inputEvents"
        >
        </v-text-field>
      </template>
    </vc-date-picker>-->
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    season: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      loadingSeason: true,
      pagination: {
        current: 1,
        total: 0,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      itemsPerPage: 10,
      hotelsNomAll: [],
      permisos: sessionStorage.getItem('permisos_auth'),
      showRowSeasons: false,
      menuFrom: false,
      menuTo: false,
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      seasonsContrateHotel: state => state.app.seasonsContrateHotel,
    }),
    computedFromDateFormatted() {
      return this.season.from ? this.$moment(this.season.from).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.season.to ? this.$moment(this.season.to).format('D-MMM-YY') : ''
    },
    minDate() {
      let date = null
      if (this.pos === 0) {
        date = new Date(Date.now()).toISOString().substr(0, 10)
      } else if (this.seasonsContrateHotel[this.pos - 1].to) {
        date = this.$moment(this.seasonsContrateHotel[this.pos - 1].to)
          .add(1, 'day')
          .toDate()
          .toISOString()
          .substr(0, 10)
      }

      return date
    },
  },
  created() {},
  methods: {
    ...mapMutations(['deleteSeasonContrateHotel']),
    activeToDate() {
      if (this.season.from) {
        this.season.to = this.$moment(this.season.from)
          .add(1, 'day')
          .toDate()
          .toISOString()
          .substr(0, 10)
      }
      setTimeout(() => {
        // this.$refs.dateTo.focus()
        this.menuTo = true
      }, 100)
    },

    sugerirNextSeason() {
      if (this.seasonsContrateHotel.length > this.pos) {
        this.seasonsContrateHotel[this.pos + 1].from = this.$moment(this.season.to)
          .add(1, 'day')
          .toDate()
          .toISOString()
          .substr(0, 10)
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>

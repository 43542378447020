<template>
  <v-col
    cols="12"
    md="12"
  >
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <v-select
              v-model="room.rooms_id"
              :disabled="$store.state.app.onlyShow"
              :items="roomsFree"
              :label="$t('lbl.rooms')"
              class="my-input mr-2"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="id"
              multiple
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('lbl.room') }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            md="1"
          >
            <v-tooltip
              v-if="releaseRoomsSeasonContrateHotel.length > 1 && !$store.state.app.onlyShow"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mt-1"
                  fab
                  dark
                  x-small
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteReleaseRoomSeasonContrateHotel(pos)"
                >
                  <v-icon small>
                    {{ icons.mdiTrashCan }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.delete') }}</span>
            </v-tooltip>
          </v-col>

          <v-col
            cols="12"
            md="1"
          >
            <v-tooltip
              v-if="!$store.state.app.onlyShow && pos === 0"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mt-1 ml-2"
                  fab
                  dark
                  x-small
                  color="primary"
                  v-bind="attrs"
                  :disabled="verifyRoomsFree"
                  v-on="on"
                  @click="addReleaseRoomSeasonContrateHotel()"
                >
                  <v-icon small>
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.insert') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="8"
      >
        <v-row>
          <div
            v-for="(relea, indC) in room.release"
            :key="indC"
            style="max-width: 90px; margin-left: 20px"
            class="mt-2"
          >
            <!--
              :append-icon="relea.days && pos === 0 ? 'mdi-arrow-down' : ''"
              @click:append="iconSet(relea.days, indC)"
            -->
            <v-text-field
              v-model="relea.days"
              :append-icon="indC === 0 ? 'mdi-arrow-right' : ''"
              :disabled="$store.state.app.onlyShow"
              :label="$t('lbl.day')"
              outlined
              dense
              hide-details
              @keypress="checkRelease($event, relea.days)"
              @input="val => (relea.days = relea.days.toUpperCase())"
              @click:append="
                indC === 0
                  ? replicaReleaseSeasonContrateHotel({
                    posRoom: pos,
                    days: relea.days,
                  })
                  : ''
              "
            ></v-text-field>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    hotel: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    planes: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop
    allRooms: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      roomsHotel: [],

      itemsRooms: [],
      show: false,
      nameRoom: '',
    }
  },
  computed: {
    ...mapState({
      roomsSeasonContrateHotel: state => state.app.roomsSeasonContrateHotel,
      releaseRoomsSeasonContrateHotel: state => state.app.releaseRoomsSeasonContrateHotel,
    }),
    roomsFree() {
      const result = []
      const roomsSelect = []
      const roomsHotel = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
            roomsHotel.push(this.roomsHotel.filter(e => e.id === element)[0])
          }
        })
      })

      roomsHotel.forEach(element => {
        if (element) {
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.releaseRoomsSeasonContrateHotel.length; index++) {
            if (this.releaseRoomsSeasonContrateHotel[index].rooms_id.includes(element.id) && index !== this.pos) {
              esta = true
            }
          }
          if (!esta) {
            result.push(element)
          }
        }
      })

      return result
    },
    verifyRoomsFree() {
      const result = []
      const roomsSelect = []
      this.roomsSeasonContrateHotel.forEach(rSeason => {
        rSeason.rooms_id.forEach(element => {
          if (!roomsSelect.includes(element)) {
            roomsSelect.push(element)
          }
        })
      })

      roomsSelect.forEach(element => {
        if (element) {
          let esta = false
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.releaseRoomsSeasonContrateHotel.length; index++) {
            if (this.releaseRoomsSeasonContrateHotel[index].rooms_id.includes(element)) {
              esta = true
            }
          }
          if (!esta) {
            result.push(element)
          }
        }
      })

      if (result.length === 0) {
        return true
      }

      return false
    },
  },
  created() {
    this.allRooms.forEach(element => {
      if (this.hotel.rooms_id.includes(element.id)) {
        this.roomsHotel.push(element)
      }
    })

    if (this.roomsHotel.length === 1) {
      this.room.room_id = this.roomsHotel[0].id
    }

    if (this.roomsSeasonContrateHotel.length === 1) {
      if (this.roomsSeasonContrateHotel[0].rooms_id.length === 1) {
        this.room.rooms_id = this.roomsSeasonContrateHotel[0].rooms_id
      }
    }
  },
  methods: {
    ...mapMutations([
      'deleteReleaseRoomSeasonContrateHotel',
      'addReleaseRoomSeasonContrateHotel',
      'replicaReleaseSeasonContrateHotel',
    ]),
    checkRelease(elEvento, model) {
      const numeros = '0123456789'
      const caracteres = 'Nn/Aa'
      const numerosCaracteres = numeros + caracteres

      // NO CUMPLE CON LOS NUMEROS O N/A
      if (!numerosCaracteres.includes(elEvento.key)) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // VERIFICAR SI ES  CERO DE PRIMERO
      if (elEvento.keyCode === 48) {
        if (model.length === 0) {
          // No se agrega
          elEvento.preventDefault()
          // eslint-disable-next-line brace-style
        }
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y YA ESTA INCLUIDA
      if (model.includes('N') && (elEvento.key === 'N' || elEvento.key === 'n')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES N Y NO ES LA PRIMERA LETRA
      else if ((elEvento.key === 'N' || elEvento.key === 'n') && model.length !== 0) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y YA ESTA INCLUIDO
      else if (model.includes('/') && elEvento.key === '/') {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES / Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        (elEvento.key === '/' && model.length === 0)
        || (elEvento.key === '/'
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y YA ESTA INCLUIDA
      else if (model.includes('A') && (elEvento.key === 'A' || elEvento.key === 'a')) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }

      // SI ES A Y NO SE HA ESCRITO NADA O ES UN NUMERO
      else if (
        ((elEvento.key === 'A' || elEvento.key === 'a') && model.length === 0)
        || ((elEvento.key === 'A' || elEvento.key === 'a')
          && (model.includes(0)
            || model.includes(1)
            || model.includes(2)
            || model.includes(3)
            || model.includes(4)
            || model.includes(5)
            || model.includes(6)
            || model.includes(7)
            || model.includes(8)
            || model.includes(9)
            || model.includes('.')))
      ) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      } else if ((elEvento.key === 'A' || elEvento.key === 'a') && (model.length === 0 || !model.includes('/'))) {
        // No se agrega
        elEvento.preventDefault()
        // eslint-disable-next-line brace-style
      }
    },
  },
}
</script>
